import "./App.css";
import { Logo, logoTyC } from "./Logos";
import { estilosLogo } from "./estilos";

import { useWindowSize } from "./hooks/useWindowsSize";

import CardTerminosCondiciones from "./cardTerminosCondiciones";
import Footer from "./Footer";
import { useState, useEffect } from "react";

function App(props) {
  // const location = window.location.href.split(':3001')[1];
  // let urlBase = process.env.REACT_APP_SRC_BASE + '/'
  // let url = process.env.REACT_APP_SRC_BASE + location;
  // console.log(`${url} === ${process.env.REACT_APP_SRC_BASE}`);
  // if (url === urlBase) {
  //   url = process.env.REACT_APP_SRC_IFRAME
  // }
  // if (window.location.pathname === "/") {
  //   url = process.env.REACT_APP_SRC_IFRAME
  //   let path = process.env.REACT_APP_SRC_IFRAME.split(".com")
  //   console.log(path);
  //   window.location.href = process.env.REACT_APP_SRC + path[1];
  // }

  const [srcIframe, setUrlIframe] = useState(process.env.REACT_APP_SRC_IFRAME);
  const {
    iframeSrc = `<iframe id='myframe' frameborder='0' class='iframe' src=${srcIframe}"></iframe>`,
  } = props;
  const sizeWindow = useWindowSize();
  const [viewModal, setviewModal] = useState(false);
  const [loading, setLoading] = useState(true);
  const [kambiPath, setkambiPath] = useState('');

  // if (document.getElementById("myframe")) {
  //   console.log(document.getElementById("myframe").contentWindow);
  //   console.log(document.getElementById("myframe").contentWindow.document);
  //   document.getElementById("myframe").contentWindow.document.body.onclick = function () {
  //     document.getElementById("myframe").contentWindow.location.reload();
  //   }
  // }

  // if (document.getElementById("myframe")) {
  //   document.getElementById("myframe").addEventListener("hashchange", (e) => {
  //     console.log(e);
  //   })
  //   document.getElementById("myframe").addEventListener("load", (e) => {
  //     console.log(e.target.contentWindow);
  //   })
  // }


  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 4000);
  }, []);

  useEffect(() => {
    if (srcIframe !== process.env.REACT_APP_SRC_IFRAME) {
      setUrlIframe(process.env.REACT_APP_SRC_IFRAME);
    }
  }, [srcIframe]);

  useEffect(()=>{
    window.history.replaceState(null, "Kambi Page", kambiPath);
  }, [kambiPath]);

  useEffect(()=>{
    // Intervalo para chequeo de Datalayer de Kambi.
    const kambiDataLayerInterval = setInterval(()=>{
      const kambiDataLayer = document.getElementById('myframe').contentWindow.dataLayer;

      // Se busca evento de GTM relacionado a navegacion.
      const kambiPagefilter = data => "kambi" in data && data.event == "kambi page view";
      let kambiPageElement = kambiDataLayer.findLast(kambiPagefilter);
      let kambiPageElementIndex = kambiDataLayer.findLastIndex(kambiPagefilter);

      // Se busca evento de GTM relacionado a navegacion en sandwich menu.
      const kambiSandwichClickFilter = data => "kambi" in data && data.event == "kambi sandwich filter click";
      let kambiSandwichClickElement = kambiDataLayer.findLast(kambiSandwichClickFilter);
      let kambiSandwichClickElementIndex = kambiDataLayer.findLastIndex(kambiSandwichClickFilter);

      // Se busca evento de GTM relacionado a cambios de eventos en QR.
      const kambiBetSlipFilter = data => "kambi" in data && (data.event == "kambi add to betslip" || data.event == "kambi remove from betslip");
      let kambiBetSlipElement = kambiDataLayer.findLast(kambiBetSlipFilter);
      let kambiBetSlipElementIndex = kambiDataLayer.findLastIndex(kambiBetSlipFilter);

      // Si existe elemento de nevegacion y no es el mismo path de URL actual...
      if(!!kambiPageElement && kambiPageElement.kambi.page.path != kambiPath){
        setkambiPath(kambiPageElement.kambi.page.path);
      }

      // Si existe elemento de nevegacion en sandwitch menu...
      if(!!kambiSandwichClickElement){
        let DLEvent = {
          event: 'sandwich_nav',
          indexInDL: kambiSandwichClickElementIndex,
          data: kambiSandwichClickElement.kambi.interaction
        };

        // Y no existe ya en el dataLayer...
        if(!window.dataLayer.find(data => data.event == 'sandwich_nav' && data.indexInDL == DLEvent.indexInDL)){
          window.dataLayer.push(DLEvent);
        }
      }

      // Si existe elemento de cambio en BetSlip...
      if(!!kambiBetSlipElement){
        let DLEvent = {
          event: (kambiBetSlipElement.event == "kambi add to betslip") ? 'betslip_add_event' : 'betslip_remove_event',
          indexInDL: kambiBetSlipElementIndex,
          data: kambiBetSlipElement.kambi.ecommerce
        };

        // Y no existe ya en el dataLayer...
        if(!window.dataLayer.find(data => (data.event == 'betslip_add_event' || data.event == 'betslip_remove_event') && data.indexInDL == DLEvent.indexInDL)){
          window.dataLayer.push(DLEvent);
        }
      }

    }, 5000)

    return ()=>clearInterval(kambiDataLayerInterval);
  }, []);

  const verClass = () => {
  };

  // useEffect(()=>{
  //   const handleMenuChange = () => {
  //     let selectedMenus = document.getElementById('myframe').contentDocument.querySelectorAll('.KambiBC-filter-menu__option--selected');
  //     let path = '';

  //     if(selectedMenus.length > 0){
  //       selectedMenus.forEach(menu=>{
  //         let element = menu;

  //         while(element.firstElementChild){
  //           element = element.firstElementChild;
  //         }

  //         path+='/'+element.innerHTML;
  //       });
  
  //       setkambiPath(path);
  //     }
  //   }

  // document.getElementById('myframe').contentWindow.addEventListener('DOMNodeInserted', handleMenuChange);
  // document.getElementById('myframe').contentWindow.addEventListener('DOMNodeRemoved', handleMenuChange);

  // document.getElementById('myframe').contentWindow.addEventListener('click', evt=>{
  //   if(evt.target.classList.contains('KambiBC-filter-menu__option')){
      
  //     let selectedMenus = document.getElementById('myframe').contentDocument.querySelectorAll('.KambiBC-filter-menu__option--selected');
  //     let path = '';

  //     selectedMenus.forEach(menu=>path+='/'+menu.innerHTML);

  //     setkambiPath(path);
  //   }
  // });
  // }, []);

  return (
    <div className="background">
      {loading && (
        <div className="wrap">
          <Logo props={estilosLogo.loader} sizeWindow={sizeWindow.width} />

          <span className="loader"></span>
        </div>
      )}

      <div className="cargado" hidden={loading}>

        <div
          style={{ backgroundColor: "#FF3900", padding: "25px", position: "sticky" }}
        >
          <Logo setUrlIframe={setUrlIframe} props={estilosLogo.header} />

          <img
            src={logoTyC}
            width="40px"
            alt="Logo terminos y condiciones"
            style={{
              position: "fixed",
              right: "15px",
              cursor: "pointer",
              width: "1.7em",
            }}
            onClick={() => setviewModal(true)}
          />

        </div>
        <CardTerminosCondiciones
          viewModal={viewModal}
          setviewModal={setviewModal}
          sizeWindow={sizeWindow.width}
        />  

        <div
          className="App"
          onMouseEnter={() => verClass()}
          dangerouslySetInnerHTML={{ __html: iframeSrc }}
        ></div>
        <Footer />
      </div>
    </div >
  );
}

export default App;
