import { Logo, logoFutbol, logoTenis, logoHockey, logoAlea, logoWla } from './Logos'
import { estilosLogo } from './estilos'

import "./styles/components/footer.css"
const Footer = () => {

    return (
        <footer>
            <div className="top-footer">
                <div className="logo-bet">
                    <Logo props={estilosLogo.footer} />
                </div>
                <div className="line"></div>
                <div className="sponsors-logos">
                    <p>SPONSOR OFICIAL DE SELECCIONES ARGENTINAS</p>
                    <div className="sponsors">
                        <div className="sponsor">
                            <img src={logoFutbol} alt="" />
                            <p>FÚTBOL</p>
                        </div>
                        <div className="sponsor">
                            <img src={logoTenis} alt="" />
                            <p>TENIS</p>
                        </div>
                        <div className="sponsor">
                            <img src={logoHockey} alt="" />
                            <p>HOCKEY</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="line"></div>
            <div className="bottom-footer">

                <div className="logos">
                    <a href="https://www.alea.org.ar/juego-responsable" rel='noreferrer' target='_blank'><img src={logoAlea} alt="" /></a>
                    <img src={logoWla} alt="" />
                </div>

                <p>JUGAR COMPULSIVAMENTE ES PERJUDICIAL PARA LA SALUD. SÓLO PARA MAYORES DE 18 AÑOS.</p>
                <div className="cliente">
                    <p><b>ATENCIÓN AL CLIENTE:</b> <a href="tel:08004441550">0800 444 1550</a></p>
                    <a href="mailto:contacto@apuestas.betwarrior.bet.ar" rel='noreferrer' target='_blank'>CONTACTO@APUESTAS.BETWARRIOR.BET.AR</a>
                </div>
            </div>
        </footer>
    )
}

export default Footer;