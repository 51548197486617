import React from "react";
import styled, { keyframes } from "styled-components";

import "./styles/components/cardTerminosCondiciones.css";

const CardTerminosCondiciones = ({ viewModal, setviewModal, sizeWindow }) => {
  return (
    viewModal && (
      <div className="modalTyC">
        <DivCard sizeWindow={sizeWindow} className="cardTyC">
          <BotonCerrar className="btnCerrar" onClick={() => setviewModal(false)}>X</BotonCerrar>
          <div className="continer">
            <h1 className="tyc">Términos y condiciones</h1>
          </div>
          <div className="card-body">
            <DivCardtext className="card-text">
              <DivtextoTyC className="textoTyC">
                {/* <p
                style={{
                  margin: "0cm",
                  textAlign: "justify",
                  textIndent: "0cm",
                  fontSize: 16,
                  fontFamily: '"Times New Roman",serif',
                  marginTop: "6.0pt",
                  marginRight: "0cm",
                  marginBottom: "12.0pt",
                  marginLeft: "0cm",
                  lineHeight: "150%",
                }}
              > */}
                <h4 className="card-title mb-1 font-weight-bold" style={{ color: "#FF3900", fontSize: "16px", fontWeight: "bold" }}>ANEXO I</h4>
                <h6 className="card-title " style={{ fontWeight: "bold" }}>
                  Reglas y normas para Juegos de Apuestas de Cuotas fijas
                </h6>
                <div className="line" style={{ height: "1.7px", width: "100%", background: "#FF3900", margin: "20px 0" }}></div>
                <h6 className="" style={{ color: "#FF3900", fontWeight: "bold", margin: "15px 0" }}>Glosario</h6>

                <p className="text">
                  <strong> Jugada: </strong> es la suma de predicciones o pronósticos seleccionados por el 
                  jugador, que deben ser pronosticadas correctamente para que sea 
                  ganadora, y cuyo premio queda establecido por la multiplicación de las 
                  cuotas para cada predicción seleccionada por el importe apostado.
                </p>

                <p className="text">
                  <strong>Ticket: </strong> es el comprobante impreso por una terminal que indicando el 
                  total de las selecciones efectuadas por el apostador y es el documento 
                  válido requerido para solicitar el pago del premio. Un ticket puede incluir 
                  una o más jugadas.
                </p>

                <p className="text">
                  <strong> Evento: </strong> es cada partido, carrera, encuentro o similar que es ofrecido para ser pronosticado y que se encuentra ofrecido dentro de la plataforma.
                </p>

                <p className="text">
                  <strong> Torneos: </strong>Los tipos de torneos pueden ser Ligas; liga sencilla, vuelta única, doble vuelta, varias vueltas, liga dividida, torneos de eliminación directa o copa, torneos de doble eliminación, torneos de multinivel, etc.
                </p>

                <p className="text">
                  <strong> Predicción: </strong> es el posible resultado para un determinado evento seleccionado por el apostador y que tiene una cuota fija.
                </p>

                <p className="text">
                  <strong> Cuota o Probabilidad: </strong> es el multiplicador o premio fijo, por acertar el resultado de un determinado evento o partido.
                </p>

                <p className="text">
                  <strong> Programa: </strong> es un listado de eventos o partidos los cuales se pueden seleccionar para conformar una jugada acorde con las pautas preestablecidas en el reglamento del organizador. Los medios de difusión de este corren por parte del organizador.
                </p>

                <p className="text">
                  <strong> Partido: </strong> es la confrontación de dos o más equipos deportivos cuyo resultado o resultado parcial se trata de predecir.
                </p>

                <p className="text">
                  <strong> Máxima selección de eventos por jugada: </strong> es la cantidad máxima de eventos o partidos que se pueden seleccionar dentro de una jugada.
                </p>

                <p className="text">
                  <strong> Mínima selección de eventos: </strong> es la cantidad mínima de eventos o partidos que se pueden seleccionar dentro de una jugada.
                </p>

                <p className="text">
                  <strong> Apostador: </strong> persona física mayor de 18 años que realice apuestas de cuotas fijas conforme las especificaciones previstas en las Reglas Generales y Específicas de los juegos.
                </p>

                <p className="text">
                  <strong> Predicción individual: </strong> es toda predicción que puede ser jugada o apostada en forma única.
                </p>

                <p className="text">
                  <strong> Fija o Fijas: </strong> es un evento o eventos que se adicionan y que participan de todas las jugadas de las combinadas.
                </p>

                <p className="text">
                  <strong> Apuesta: </strong> es un pronóstico que, al ser validado, se convierte en apuesta.
                </p>

                <p className="text">
                  <strong> QR: </strong> es un código de respuesta rápida que permite, al ser escaneado, mostrar la información de una jugada. Esta misma, deberá ser validada para transformarse en apuesta.
                </p>
                {/* </p> */}
                <>
                  <h6 className="card-title text-center mb-3">
                    SECCIÓN I: REGLAS GENERALES
                  </h6>
                  <p
                    style={{
                      margin: "0cm",
                      textAlign: "justify",
                      textIndent: "0cm",
                      fontSize: 15,
                      fontFamily: "Archivo",
                      marginTop: "6.0pt",
                      marginRight: "0cm",
                      marginBottom: "12.0pt",
                      marginLeft: "0cm",
                      lineHeight: "150%",
                    }}
                  >
                    <strong>Artículo 1 – Normas generales </strong>
                    <br />
                    Estas normas contienen términos y condiciones para la participación en los juegos de apuestas de cuotas fijas, conforme a lo organizado por TECNO ACCIÓN SALTA S.A., en adelante “el Organizador”.
                    <br /> 1. Las presentes reglas rigen todos los aspectos relacionados con la organización y la realización de los juegos de apuestas de cuotas fijas, según lo organizado, administrado y operado por el Organizador. También regulan la relación entre el Organizador y los jugadores que participan en estos juegos. Estas reglas y normas tienen vigencia para todos los juegos de apuestas de cuotas fijas, ya sea que se trate de deportes en equipo o individuales u otros eventos no deportivos que por su propia índole permiten realizar apuestas sobre su resultado.
                    <br /> 2. La participación de los apostadores en los juegos de apuestas de cuotas fijas puede implementarse a través de puntos de venta autorizados y/o en las modalidades que se autoricen previamente por el ENREJA. Las apuestas que se realicen en los Puntos de venta serán mediante la utilización de código QR y/o Voz Alzada.
                    <br /> 3. La administración financiera de los juegos de apuestas de cuotas fijas (pago de premios a los jugadores o distribución a los beneficiarios, etc.) es responsabilidad exclusiva del Organizador.
                    <br /> 4. La participación de los apostadores en los juegos implicará su reconocimiento y aceptación de los presentes términos y condiciones.
                    <br /> 5. En cualquier momento el Organizador, previa autorización del Enreja podrá efectuar las modificaciones que crea convenientes a los presentes términos y condiciones, las que serán dadas a conocer a los apostadores, quedando entendido que éstos las tendrán por aceptadas al realizar jugadas con posterioridad a la entrada en vigor de las modificaciones introducidas.
                    <br /> 6. La eventual nulidad de alguna de las cláusulas del presente no implicará la nulidad de las restantes cláusulas. Se deja constancia que, ante la ocurrencia de situaciones no previstas en el presente, éstas serán resueltas por el Organizador, con intervención del ENREJA, en los casos que así la requieran.
                    <br />
                    <strong>
                      Artículo 2 – Realización de juegos de apuestas de cuotas
                      fijas{" "}
                    </strong>
                    <br />
                    1. Las apuestas en los juegos de apuestas de cuotas fijas podrán hacerse a partir de la publicación del programa (Físico o Digital) que tendrá un período de validez específico a determinar exclusivamente por el Organizador. Este Programa enumera todos los eventos a los que se puede pronosticar, con fechas y horas de realización de dichos eventos, las cuotas iniciales, las instrucciones para apostar y las condiciones especiales que puedan tener vigencia.
                    <br />
                    2. El Programa se comunicará por medio digital (web del organizador, por ejemplo, aplicación específica, etc) y/o a través de cualquier otro medio que decida el Organizador, previa autorización del ENREJA.
                    <br />
                    3. En el caso que en el Programa de eventos faltaran datos o información específica con respecto a alguno de los eventos, se observaran errores o se produjera una demora en la distribución del programa, el Organizador podrá publicar esos datos o información por cualquier medio que disponga e informar dicha eventualidad al ENREJA.
                    <br />
                    4. El período para recibir apuestas de cuotas fijas ofrecidas en el Programa comienza con la activación de aceptación de apuestas en el Sistema Central de Cómputos y finaliza con el inicio del último evento apostado.
                    <br />
                    5. Las apuestas sólo se aceptan durante el período de validez del evento y hasta llegada la fecha y hora fijadas para el primer evento elegido por el apostador en su apuesta, según lo validado por el Sistema Central de Cómputos.
                    <br />
                    6. En todos los casos, para definir la validez de las apuestas, los datos registrados en el Sistema Central de Cómputos del Organizador prevalecen sobre cualquier otro dato.  Es decir: la validez de una apuesta está atada a la emisión de un ticket válidamente emitido.
                    <br />
                    7. Los juegos de apuestas de cuotas fijas consisten en las opciones que puede elegir un jugador, como posible resultado de cada evento o grupo de eventos ofrecido, así como también la cantidad de predicciones que un apostador puede elegir para crear una jugada. El Organizador es responsable de establecer y publicar el valor de una jugada.
                    <br />
                    8. Los juegos de apuestas de cuotas fijas se ofrecen con cuotas predeterminados para cada selección por separado o como un todo unitario, según lo elegido por el jugador. Las cuotas se presentan en formato de dos decimales. Una vez validado el ticket por el Sistema Central de Cómputos, las cuotas de ese ticket no cambian excepto situaciones particulares mencionadas en el presente reglamento y sus anexos. Las cuotas iniciales se publican en el Programa. El Organizador tiene derecho a cambiar las cuotas en cualquier momento hasta el inicio del evento seleccionado.
                    <br />
                    <strong>
                      Artículo 3 – Participación de apostadores en juegos de apuestas de cuotas fijas.
                    </strong>
                    <br />
                    <br /> 1. La participación en juegos de apuestas de cuotas fijas está abierta al público en general que sea mayor de 18 años, con la condición de que los apostadores acepten de forma total e incondicional estas Reglas y Normas, y acepten, asimismo, las reglas y condiciones especiales decididas por el Organizador, y autorizadas por el ENREJA, que se publicarán en el Programa Digital en distintas oportunidades.
                    <br /> 2. Para que un apostador participe en los juegos de apuestas de cuotas fijas es necesario crear, al menos, una jugada y pagar el valor de la jugada o jugadas creadas o, si se lo desea, multiplicar este valor varias veces. La jugada se generará en los puntos de venta de la red autorizada, a voz alzada o con la presentación de una jugada preconfeccionada por el apostador en su dispositivo móvil, y validada a través de un QR. El pago de la jugada o jugadas quedará completado una vez que el Sistema Central de Cómputos haya validado la apuesta, cuya validez se probará por medio de un ticket. Este ticket contendrá las cuotas válidas al momento de la aceptación de la apuesta, los demás pronósticos del jugador y cualquier otro elemento necesario para que la apuesta sea única.
                    <br /> 3. Es de responsabilidad del Organizador determinar e informar a todos los jugadores por medio del Programa, el monto máximo de pago por combinación o combinaciones de apuestas para participar en un juego de apuestas con cuotas fijas. En caso de que el valor de la apuesta exceda el monto máximo determinado por el Organizador, la apuesta no será aceptada.
                    <br />{" "}
                    <strong> Artículo 4 – Aceptación de la apuesta </strong>
                    <br /> 1. El Organizador tiene derecho a aceptar o rechazar cualquier jugada en cualquier momento, sin la obligación de justificar su decisión.
                    <br /> 2. Si, a exclusivo criterio del Organizador y ante una situación determinada, existieran razones especiales para llegar a un acuerdo especial con un jugador, se aplicará un procedimiento especial que implicará negociar con el jugador para contemplar posibles cambios en sus selecciones. El acuerdo final será certificado mediante la validación de la apuesta por el Sistema Central de Cómputos y ante el ENREJA.
                    <br /> 3. Una jugada que contiene un evento que se ha pospuesto o cancelado sólo se aceptará en las condiciones estipuladas en estas Reglas y Normas.
                    <br /> <strong> Artículo 5 - Anulación de la apuesta</strong>
                    <br /> 1. Una apuesta se podrá anular hasta 5 (cinco) minutos después de haber sido validada y siempre que no haya comenzado el primer evento de la selección efectuada por el apostador.
                    <br /> 2. Si por alguna razón la cancelación de la apuesta no se pudiese concretar porque el Sistema Central de Cómputo no reconoce automáticamente la apuesta, la cancelación no se llevará a cabo quedando la misma a exclusiva responsabilidad del Organizador y en este caso será notificado el ENREJA.
                    <br /> 3. Cuando se anula una apuesta, todas las combinaciones incluidas en la apuesta también se anularán y se reintegrará al apostador el monto de la apuesta abonado.
                    <br /> Artículo 6 - Resultados de los Eventos
                    <br /> 1. Las predicciones acertadas de las selecciones del apostador se basan en los resultados oficiales anunciados por el Organizador para todos los eventos del Programa organizados de acuerdo con las reglas y las normas oficiales del juego.
                    <br /> 2. En caso de que se posponga o cancele uno o más eventos para los cuales se han aceptado apuestas, y antes de que estos cambios se hayan dado a conocer y hayan sido ingresados en el Sistema Central de Cómputos, los resultados de los eventos ofrecidos se determinarán sobre la base de las reglas contenidas en estas normas.
                    <br />{" "}
                    <strong>
                      {" "}
                      Artículo 7 – Apuestas ganadoras – Pago de premios
                    </strong>
                    <br /> 1. Cada jugada ingresada en el Sistema Central de Cómputos es ganadora cuando el apostador ha acertado todas las predicciones que ha seleccionado. En este caso se pagará un premio equivalente al valor de la jugada, multiplicado por la apuesta final por todas las cuotas válidas al momento de la validación de la jugada. En el caso de que se ofreciera una cuota para la totalidad de una combinación elegida por el jugador, el premio ganador pagado al apostador equivaldrá al valor de la jugada multiplicado por la cuota dada para esta combinación, vigente al momento de la validación.
                    <br /> 2. El pago a los ganadores, de acuerdo con el párrafo anterior, sólo se realizará una vez terminados todos los eventos incluidos en la apuesta y después de que se confirme el registro correspondiente en el Sistema Central de Cómputos. La forma de redondear el monto de los premios para todas las predicciones elegidas por el apostador será la validada en el ticket.
                    <br /> 3. El Organizador determinará el monto máximo de los premios que se pagarán a través de la red de ventas autorizada por ENREJA y cuando supere dicho monto se pagará en las oficinas que determine el Organizador para tal fin.
                    <br /> 4. En caso de error evidente, por ejemplo, error de tipeo u otro, en la cuota para las predicciones sobre cuya base se calcula el monto del premio correspondiente a una apuesta ganadora, el Organizador se reserva el derecho de calcular el monto del premio tomando en cuenta la cuota correcta y pagar como premio al jugador el monto obtenido de este cálculo. En estos casos no se aceptará reclamo alguno por parte de los apostadores.
                    <br /> 5. En el caso en que, por alguna razón, las jugadas ganadoras no fueran reconocidas automáticamente, el Organizador podrá, a su exclusivo criterio, aplicar procedimientos especiales, partiendo de la base de que el Sistema Central de Cómputos pueda reconocer las jugadas ganadoras de algún modo.
                    <br /> 6. En caso de pérdida, destrucción parcial o completa cuando haya ticket impreso, e incluso, en el caso en que no se pueda recuperar la información del ticket ganador, el Organizador se exime de toda responsabilidad de pagar el monto del mismo o de ofrecer cualquier otro tipo de compensación, toda vez, que el ticket es al portador.
                    <br /> 7. Los premios deberán cobrarse dentro de los 10 (diez) días corridos contados a partir del final del periodo válido del último evento del ticket. En caso de que el último día para el cobro sea feriado, vencerá el siguiente día hábil.
                    <br /> 8. En el caso en que el Organizador determine que existe duda razonable de que el resultado de un evento ha sido manipulado en transgresión de las reglas y reglamentos oficiales que rigen dicho evento, o en el caso en que exista evidencia que cuestione la credibilidad de un evento, el Organizador se reserva el derecho de suspender el pago de premios.
                    <br />
                    <br /> <strong> Artículo 8 - Máximo de premios</strong>
                    <br /> El Organizador determinará el monto máximo de premios por apuesta y tipo de apuesta que se pagará y lo anunciará a través del medio que considere conveniente. Si algún apostador o grupo de apostadores formula apuestas idénticas o similares, con el propósito de evitar la aplicación del procedimiento especial establecido por el artículo 4, párrafo 2 de este reglamento, así como las regulaciones establecidas por este artículo, el Organizador se 10 reserva el derecho de aplicar el máximo de premios, según se definen en este artículo, al total de las apuestas definidas anteriormente.
                    <br /> <strong> Artículo 9 – Disputas</strong>
                    <br /> 1. Un apostador que presenta una apuesta supuestamente ganadora validada que no es reconocida como ganadora por el Sistema Central de Cómputos o que registra supuestamente premios inferiores a los que debería recibir, tiene el derecho de enviar su queja al Organizador en un plazo de 10 (diez) días corridos contados a partir del momento en que los resultados de todos los eventos del Programa respectivo fueran ingresados al Sistema Central de Cómputos.
                    <br /> 2. El Organizador no tendrá responsabilidad alguna por daños causados por cualquier pérdida o destrucción parcial o total del ticket de la jugada, una vez que haya sido entregado al apostador, ni por datos de la jugada que no puedan ser recuperados.
                    <br /> 3. El Organizador sólo abonará premios cuando el poseedor del comprobante físico (ticket) presente el original emitido por un dispositivo autorizado.
                    <br />
                    <br />{" "}
                    <strong>
                      Artículo 10 - Fuerza mayor - La responsabilidad del
                      Organizador
                    </strong>
                    <br /> En casos de fuerza mayor que imposibiliten la realización de los juegos de apuestas de cuotas fijas y el pago a los ganadores, el Organizador no tendrá responsabilidad alguna para con los apostadores, excepto en los casos en que fuera posible el reconocimiento automático de las jugadas, en las cuales se reintegrará el monto pagado por los apostadores.
                    <br /> Fuerza mayor comprende todos aquellos hechos que escapan al control del Organizador y son imprevisibles e inevitables, de modo que cualquier planificación de medidas de defensa que hubiera hecho el Organizador no habría podido evitar estos sucesos. Fuerza mayor cubre también, sin limitación, hechos como desastres naturales, inundaciones, pandemia, terremotos, incendios, guerra, huelgas, embargos, actos de terrorismo, o cualquier otro evento de la misma índole.
                    <br /> <strong>Artículo 11 - Seguridad de los datos</strong>
                    <br /> Los datos sobre todas las transacciones ingresadas en el Sistema Central de Cómputos se conservarán durante 60 (sesenta) días corridos bajo la responsabilidad del Organizador.
                    <br />{" "}
                    <strong>Artículo 12 – Publicación de las reglas</strong>
                    <br /> Estas reglas, así como los términos y condiciones que rigen los juegos de apuestas con cuotas fijas y la descripción en detalle en la sección II de estas reglas para todos los juegos de apuestas de cuotas fijas ofrecidos, se comunicarán a los jugadores mediante programas, material impreso, medios electrónicos u otros medios que decida el Organizador, y, a partir del momento de su anuncio, se consideran parte integrante del reglamento oficial del juego.
                    <br />{" "}
                    <strong>Artículo 13 – Situaciones no contempladas</strong>
                    <br /> En el caso de plantearse alguna situación no contemplada en el presente reglamento la Organizadora decidirá sobre la misma y en caso de disconformidad el apostador podrá apelar ante el Ente Regulador del Juego de Azar
                  </p>
                </>
                <h4 className="card-title text-center mb-3">ANEXO II </h4>
                <h6 className="card-title text-center mb-3">
                  REGLAS ESPECÍFICAS DEL JUEGO FUTBOL
                </h6>
                <h6 className="card-title text-center mb-3">
                  Disposiciones Generales
                </h6>
                <p
                  style={{
                    margin: "0cm",
                    textAlign: "justify",
                    textIndent: "0cm",
                    fontSize: 15,
                    fontFamily: "Archivo",
                    marginTop: "6.0pt",
                    marginRight: "0cm",
                    marginBottom: "12.0pt",
                    marginLeft: "0cm",
                    lineHeight: "150%",
                  }}
                >
                  <br /> 1. Para cada resultado posible anunciado en el Programa se ofrecen cuotas que el cliente puede usar para calcular los premios. Las cuotas publicadas en el Programa serán las cuotas iniciales. Una vez validado el ticket por el Sistema Central de Cómputos, las cuotas para ese ticket no cambian excepto situaciones particulares mencionadas en el presente reglamento y sus anexos. El Organizador se reserva el derecho de modificar los Cuotas cuando lo crea necesario y hasta antes de validar una jugada.
                  <br /> 2. El Organizador decidirá y publicará, a través del medio que considere conveniente, las reglas relacionadas con los diferentes tipos de apuestas. Una jugada puede incluir una serie de predicciones de resultados no relacionados con el mismo evento. Esto depende de la decisión del Organizador en cuanto al mínimo de eventos que el apostador podrá elegir en una jugada. Esta información se publicará de la forma en la que el Organizador lo considere conveniente.
                  <br /> 3. El apostador hará sus predicciones de acuerdo con las instrucciones impartidas por el Organizador. Estas instrucciones son publicadas a los apostadores mediante programas, material impreso, medios electrónicos u otros medios que decida el Organizador.
                  <br /> 4. No se podrá combinar más de un tipo de apuesta de un mismo evento en la misma jugada. Las posibilidades y maneras de combinar varios tipos de apuestas diferentes en una misma jugada, suponiendo que no se refieran al mismo evento, se publicarán en el Programa. Las excepciones se darán en aquellos casos en los que se pide pronosticar el autor del primer gol, el autor de cualquier gol y el autor del último gol, que se pueden combinar entre si, o la hora del primer gol que se puede combinar con los pronósticos de autor del primer gol, autor de cualquier gol y autor del último gol.
                  <br /> 5. Si el evento cambia la ubicación original y/o de superficie de juego, no se considerará un motivo para anular apuestas a menos que lo especifiquen las reglas específicas del deporte y/o la nueva ubicación del desarrollo del evento sea el campo (local) de alguno de los participantes en el partido. Como regla general, el Organizador se referirá al equipo local (anfitrión) y al equipo visitante (visitante) según la definición proporcionada por el órgano que gestiona ese partido/competición en particular. Las apuestas de partidos jugados en los llamados "campos neutros" serán válidas sin que esa información se detalle en la apuesta y/o el posicionamiento de los equipos en los marcadores/pantallas. En casos donde haya discrepancias entre las posiciones de los equipos/participantes en la página web oficial y el tablero de apuestas, y dichas discrepancias causen un efecto significante en las cuotas del partido/competición el Organizador anulará las apuestas afectadas. Estas eventualidades se consideran únicamente en casos donde la discrepancia tiene efecto material y visible en las cuotas. En estos casos, dichos eventos se considerarán como jugados en instalaciones neutrales y todas las apuestas permanecen sin que importe la posición de los equipos/participantes en la página web oficial y su situación en el tablero/pantalla de apuestas.
                  <br /> 6. Las predicciones elegidas por los jugadores se podrán combinar en apuestas combinadas (sobre la base del número de predicciones elegidas en una jugada). Las apuestas combinadas ofrecidas a los apostadores se anunciarán mediante los medios dispuestos por el Organizador. Cuando los apostadores opten por una apuesta combinada, será posible usar la opción Fija o Fijas, que son pronósticos incluidos en todas las jugadas.
                  <br /> 7. Las jugadas que contengan predicciones que no se pueden verificar serán tomadas en cuenta en los siguientes supuestos:
                  <br /> - Cuando contenga dos o más predicciones verificables
                  <br /> - Cuando contenga una predicción verificable que puede ser jugada como individual. Las predicciones que no se pueden verificar a los cuáles se hace referencia anteriormente, reciben cuota de uno (1,00). En caso de no cumplirse los supuestos anteriores, se devolverá el importe de la jugada al apostador.
                  <br /> 8. Las jugadas que contienen predicciones acerca de un evento que fueron insertadas una vez comenzado dicho evento a causa de la modificación del horario de inicio, podrán ser aceptadas, pero la cuota para dicho evento será de uno (1,00). En estos casos, las jugadas que contengan un evento que haya comenzado serán tomadas en cuenta según el criterio establecido en el punto 7. Las jugadas que no cumplan las exigencias indicadas anteriormente no participarán en el juego y se procederá a devolver el monto pagado por dichas jugadas a los apostadores, una vez terminados todos los eventos en los que el jugador esté participando.
                  <br /> 9. En ciertas circunstancias en las que el Organizador determine que hay razones especiales, algunas apuestas ofrecidas podrán tener una cuota de uno (1,00). Esto es un derecho exclusivo del Organizador.
                </p>
                <h6 className="card-title text-center mb-3">Tipos de Apuestas</h6>
                <p
                  style={{
                    margin: "0cm",
                    textAlign: "justify",
                    textIndent: "0cm",
                    fontSize: 15,
                    fontFamily: 'Archivo',
                    marginTop: "6.0pt",
                    marginRight: "0cm",
                    marginBottom: "12.0pt",
                    marginLeft: "0cm",
                    lineHeight: "150%",
                  }}
                >
                  <br />
                  <strong>
                    1. Resultado final de un partido de fútbol El apostador hará un pronóstico sobre el resultado final de un partido de fútbol.{" "}
                  </strong>
                  <br />
                  1. El Organizador publicará, por el medio que crea más conveniente, y en caso que lo considere, las ventajas en goles para uno de los dos equipos. En este caso, el triunfo para un equipo es cuando el resultado del partido supera la ventaja de goles dada al otro equipo inicialmente. Se considerará un empate cualquier victoria en la que la diferencia de goles anotados por un equipo es igual a la ventaja otorgada al rival. La ventaja puede ser un número decimal y, en este caso, no es posible tener un empate como resultado final. El Organizador se reserva el derecho de cambiar la ventaja de goles en cualquier momento. Las ventajas iniciales se publicarán en el Programa. Las ventajas que se consideran para dar una jugada por ganadora son las válidas en el momento de la validación de la jugada por parte del Sistema Central de Cómputos.
                  <br />
                  2. El resultado final anunciado por el organizador será considerado el resultado final. Este resultado está basado en el resultado que haya sido establecido durante el tiempo normal de ese evento o partido. Existe la posibilidad que el resultado final se dé después del tiempo adicional o en base a penales. Si este fuese el caso, el mismo estará establecido en el programa. En ciertas circunstancias particulares, en caso de que haya habido una suspensión y en caso que el organizador considere que hay razones especiales, esta apuesta podrá ser re programada y ofrecida nuevamente por la duración faltante del evento o partido. En ese caso será anunciado de cualquier manera posible.
                  <br />
                  3. A no ser que se indique lo contrario en las normas específicas del deporte o en conjunto con la oferta de la apuesta, los eventos específicos que formen parte de torneos/competiciones que no se disputen, sean pospuestos y/o aplazados por/a una fecha superior “a las 12 horas” desde la última fecha prevista por el órgano responsable debido a las inclemencias del tiempo, problemas con el público o situaciones similares, serán declaradas nulas con las siguientes excepciones, en las que las apuestas continuarán en pie:
                  <br />• Eventos cuyos horarios de inicio no hayan sido confirmados de manera oficial por el órgano responsable en el momento de cursar la apuesta;
                  <br />• Eventos pospuestos debido a problemas de planificación/retransmisión televisiva pero que cuya disputa siga estando prevista en esa misma jornada/ronda (según proceda) y cuyo cambio no afecte el orden del encuentro oficial de ninguno de los participantes en la oferta;
                  <br />• Eventos cuyos horarios de inicio se vean anticipados (adelantados) pero siga estando prevista su disputa en la misma jornada/ronda (según proceda).
                  <br />
                  4. Si un partido se suspende durante el segundo tiempo, y el resto del partido no se juega dentro del día calendario siguiente al establecido en forma oficial en el Programa (en hora local), el resultado en el momento de la suspensión se considerará el resultado final.
                  <br />
                  5. Si un partido es suspendido, reiniciado y terminado dentro del día calendario siguiente a la fecha publicada en el Programa (en hora local), el resultado válido será el que se dé al finalizar el partido.
                  <br />
                  <strong>
                    2. Resultado del primer tiempo / resultado final de un partido de fútbol
                  </strong>
                  <br />
                  El apostador deberá predecir el resultado del primer tiempo de un partido de fútbol en combinación con el resultado final del mismo partido.
                  <br />
                  1. El resultado del primer tiempo y el resultado final del partido anunciados oficialmente por el Organizador serán considerados los resultados finales. Estos resultados se basan en el resultado obtenido en el período de duración reglamentario del partido. Puede suceder que el resultado final sea el obtenido en tiempo suplementario, al marcar un gol de oro o al desempatar por penales. Si es el caso, se publicará en el Programa.
                  <br />
                  2. Si un partido se cancela o se posterga por más de un día en relación con el día establecido en el Programa (en horario local), o si un partido se suspende antes del inicio del segundo tiempo y el tiempo restante desde la suspensión hasta el término reglamentario del partido no se jugara al día siguiente del día estipulado en el Programa (en horario local), las predicciones de los apostadores respecto del resultado del primer tiempo y del resultado final serán aceptadas, pero la cuota será de uno (1,00).
                  <br />
                  3. Si un partido se suspende durante el segundo tiempo, y el tiempo restante del partido no se juega dentro del día siguiente al día establecido en forma oficial en el Programa (en horario local), el resultado del primer tiempo y el resultado final del partido al momento de la suspensión se considerarán el resultado final.
                  <br />
                  4. Si un partido es suspendido, se reanuda y concluye dentro del día siguiente al establecido en forma oficial en el Programa (en horario local), el resultado válido del primer tiempo y del resultado final será el resultado correspondiente al término del partido.
                  <br />
                  <strong>
                    3. Goles anotados en el primer tiempo de un partido de fútbol “Menos” - “Más” en relación con un límite especificado
                  </strong>
                  <br />
                  El apostador deberá predecir si el número total de goles anotados en el primer tiempo de un partido de fútbol será “Menos” o “Más” en relación con el límite publicado en el Programa.
                  <br />
                  1. El número total de goles anotados en el primer tiempo de un partido de fútbol es la suma de goles anotados por ambos equipos tal como lo anuncie oficialmente el Organizador, basándose en los resultados obtenidos al terminar el tiempo reglamentario del primer tiempo del partido.
                  <br />
                  2. Si un partido se cancela o se posterga por más de un día contado a partir del día establecido en el Programa (en horario local), las predicciones “Menos” – Más” para el primer tiempo del partido de fútbol serán aceptadas, pero tendrán una cuota de uno (1,00).
                  <br />
                  3. Si un partido es suspendido antes del término del primer tiempo, y el tiempo restante desde la suspensión hasta el final reglamentario del partido no se juega dentro del día siguiente al establecido en el Programa (en horario local) y, si al momento de la suspensión, el número de goles marcados ha excedido el límite establecido por el Programa, el resultado “Más” es considerado el resultado ganador, mientras que el resultado “Menos” es considerado el resultado perdedor. Si un partido se suspende antes del final del primer tiempo, y el tiempo restante desde la suspensión hasta el final reglamentario del partido no se jugará dentro del día siguiente al establecido en el Programa (en horario local) y, si al momento de la suspensión, el número total de goles marcados es menor al límite publicado en el Programa, ambos resultados, “Más” y “Menos”, tendrán una cuotade uno (1,00).
                  <br />
                  4. Si un partido es suspendido, se reanuda y concluye dentro del día siguiente al indicado oficialmente en el Programa (en horario local), el número total de goles anotados en el primer tiempo será el número de goles anotados en el primer tiempo al finalizar el partido.
                  <br />
                  <strong>4. Marcador exacto de un partido de fútbol</strong>
                  <br />
                  El apostador deberá predecir el resultado exacto de un partido
                  de fútbol.
                  <br />
                  1. El resultado válido es el resultado que el Organizador anuncia oficialmente, basado en los resultados obtenidos al finalizar el tiempo reglamentario del partido.
                  <br />
                  2. Si un partido se cancela o posterga por más de un día contado a partir del día establecido en el Programa (en horario local), o si un partido se suspende antes del inicio del segundo tiempo y el tiempo restante desde la suspensión hasta la finalización reglamentaria del partido no se juega dentro del día siguiente al estipulado en el Programa (en horario local), las apuestas de los apostadores respecto al marcador exacto serán aceptadas, pero la cuota será de uno (1,00).
                  <br />
                  3. Si un partido se suspende durante el segundo tiempo, y el tiempo restante del partido no se juega dentro del día siguiente al establecido en forma oficial en el Programa (en horario local), el marcador exacto en el momento de la suspensión será considerado el marcador exacto final.
                  <br />
                  4. Si un partido es suspendido, se reanuda y concluye dentro del día siguiente al establecido en forma oficial en el Programa (en horario local), el marcador exacto válido será el marcador al término del partido.
                  <br />
                  <strong>
                    5. Jugador que anota el primer gol en un partido de fútbol
                  </strong>
                  <br /> El apostador deberá predecir qué jugador de fútbol marcará el primer gol durante un partido de fútbol.
                  <br />
                  1. El primer gol anotado es el que se marca durante los 90 minutos de juego, incluido el tiempo adicional determinado por el árbitro debido a infracciones y otros descuentos. Los tiempos suplementarios y el desempate por penales no cuentan para este tipo de apuestas.
                  <br />
                  2. Las apuestas sobre el primer gol anotado por jugadores que no participaran en el partido o entraran en reemplazo de otro jugador después de que se anotó el primer gol, tendrán dividendo de uno (1,00).
                  <br />
                  3. Si el primer gol es un “auto gol”, no se lo toma en cuenta al decidir el primer tanto anotado. Serán excepción los casos en que se ofrezcan cuotas si el primer gol es un “auto gol”. Cuando está en discusión quién anotó el primer gol y si este primer gol es un auto gol, se tomará en cuenta la decisión oficial de la asociación que organiza el partido.
                  <br />
                  4. Si un partido se suspende antes de que se inicie el segundo tiempo, y después de que se haya marcado el primer gol, y el tiempo restante del partido no se juega dentro del día siguiente al estipulado en forma oficial en el Programa (en horario local), las predicciones para el primer anotador serán consideradas válidas. Si un partido se suspende antes del inicio del segundo tiempo, no se ha marcado ningún gol, y el tiempo restante del partido no se juega dentro del día siguiente al estipulado en forma oficial en el Programa (en horario local), a las predicciones para el primer anotador se les dará dividendo de uno (1,00).
                  <br />
                  5. Si un partido se suspende después de iniciado el segundo tiempo, y el tiempo restante del partido no se juega dentro del día siguiente al establecido en forma oficial en el Programa (en horario local), la predicción sobre el primer jugador que convierta un gol se basará en el resultado al momento de la suspensión.
                  <br />
                  6. Si un partido es suspendido, se reanuda y concluye dentro del día siguiente al establecido en forma oficial en el Programa (en horario local), la predicción del primer anotador se basará en el resultado correspondiente al término del partido.
                  <br />
                  7. Si un partido se cancela o posterga por más de un día contado a partir del día establecido en el Programa (en horario local), las apuestas que hayan sido realizadas por los jugadores serán tomadas como válidas con la cuotade uno (1,00).
                  <br />
                  8. También se permitirá combinar en una misma jugada la predicción para anotador del primer gol con la predicción del anotador del último gol y/o con la predicción del anotador de cualquier gol, siempre y cuando las predicciones no se basen sobre un mismo jugador de fútbol.
                  <br />
                  <strong>
                    6. Jugador que anota el último gol de un partido de fútbol
                  </strong>
                  <br />
                  El apostador deberá predecir qué jugador anotará el último gol
                  durante un partido de fútbol.
                  <br />
                  1. El último gol anotado es el que se marca durante los 90 minutos de juego, incluido el tiempo adicional determinado por el árbitro debido a infracciones y otros descuentos. El tiempo suplementario y el desempate por penales no se consideran para este tipo de jugadas.
                  <br />
                  2. Las apuestas para los jugadores de fútbol que no participaron en el partido o fueron reemplazados por otro jugador antes de que se anotara el último gol, tendrán dividendo de uno (1.00).
                  <br />
                  3. Si el último gol es un “auto gol”, no se tomará en cuenta al decidir el último tanto anotado, salvo en los casos en que las cuotas ofrecidas para el último gol contemplen el caso “auto gol”. Cuando está en discusión quién anotó el último gol y si este último gol es un auto gol, se tomará en cuenta la decisión oficial de la asociación que organiza el partido.
                  <br />
                  4. Si un partido se suspende antes de que se inicie el segundo tiempo, y el tiempo restante del partido no se juega dentro del día siguiente al estipulado en forma oficial en el Programa (en horario local), las predicciones para el último anotador tomarán la cuota de uno (1.00).
                  <br />
                  5. Si un partido se suspende después del inicio al segundo tiempo, y el tiempo restante del partido no se juega dentro del día siguiente al establecido en forma oficial en el Programa (en horario local), la predicción del último anotador se basará en el resultado al momento de la suspensión.
                  <br />
                  6. Si un partido es suspendido, se reanuda y concluye dentro del día siguiente al establecido en forma oficial en el Programa (en horario local), la predicción del último anotador se basará en el resultado correspondiente al término del partido.
                  <br />
                  7. Si un partido se cancela o posterga por más de un día en relación con el día establecido en el Programa (en horario local), las apuestas ya realizadas por los jugadores serán aceptadas, pero la cuota será de uno (1.00).
                  <br />
                  8. También se permitirá combinar en una misma jugada la predicción para anotador del primer gol con la predicción del anotador del último gol y/o con la predicción del anotador de cualquier gol, siempre y cuando las predicciones no se basen sobre un mismo jugador de fútbol.
                  <br />
                  <strong>
                    7. Jugador que anota cualquier gol en un partido de fútbol
                  </strong>
                  <br />
                  El apostador deberá predecir quién anotará un gol durante un
                  partido de fútbol.
                  <br />
                  1. Un gol anotado es el que se marca durante los 90 minutos de juego, incluido el tiempo adicional determinado por el árbitro debido a infracciones y otros descuentos. Los tiempos suplementarios y el desempate por penales no se consideran para este tipo de jugadas.
                  <br />
                  2. Las apuestas para los jugadores que no participarán en el
                  partido tendrán cuota de uno (1,00).
                  <br />
                  3. Si un partido es suspendido antes del comienzo del segundo tiempo, después de que se hayan anotado uno o más goles, y el tiempo restante del partido no se juega dentro del día siguiente al establecido oficialmente en el Programa (en horario local), las predicciones acerca del anotador o los anotadores de goles ya marcados se considerarán ganadoras, mientras que las predicciones para los otros jugadores que no anotaron goles recibirán una cuota de uno (1.00). Si un partido es suspendido antes del inicio del segundo tiempo, no se han anotado goles, y el tiempo restante del partido no se juega dentro del día siguiente al establecido por el Programa oficial (en horario local), las predicciones para el anotador o anotadores tomarán dividendo de uno (1,00).
                  <br />
                  4. Si el partido es suspendido después del comienzo del segundo tiempo, y el tiempo restante del partido no se juega dentro del día siguiente al establecido por el Programa (en horario local), las predicciones para el anotador o anotadores de cualquier gol en el partido se basarán en los resultados del partido al momento de la suspensión.
                  <br />
                  5. Si el partido es suspendido, se reanuda y concluye dentro del día siguiente al establecido en el Programa oficial (en horario local), la predicción del anotador de cualquier gol se basará en los resultados obtenidos al finalizar el partido.
                  <br />
                  6. Si un partido es cancelado o postergado por más de 24 horas después de lo establecido en el Programa (hora local), las apuestas ya realizadas serán aceptadas pero las cuotas serán de uno (1,00).
                  <br />
                  7. La predicción para el anotador de cualquier gol en un partido de fútbol se puede combinar con las predicciones de primer anotador y/o último anotador del mismo partido, siempre y cuando las predicciones no se basen sobre un mismo jugador de fútbol.
                  <br />
                  <strong>
                    8. Primer equipo que convierte un gol en un partido de fútbol
                  </strong>
                  <br />
                  El apostador deberá predecir qué equipo anotará el primer gol en un partido de fútbol.
                  <br />
                  1. El equipo que marca el primer gol es el que el Organizador anuncia en forma oficial, en base al resultado que se produce al final del tiempo reglamentario del partido. Si no hay goles durante el tiempo reglamentario por parte de ningún equipo, entonces las predicciones serán perdedoras.
                  <br />
                  2. Si un partido se suspende antes del comienzo del segundo tiempo, después de que se haya marcado el primer gol, y el tiempo restante del partido no se juega dentro del día siguiente al estipulado en forma oficial en el Programa (en horario local), se considerarán válidas las predicciones que incluyan al equipo que anote el primer gol. Si un partido se suspende antes del inicio del segundo tiempo, y no se ha convertido ningún gol, y el tiempo restante del partido no se juega dentro del día del día estipulado en forma oficial en el Programa (en horario local), se les asignará la cuota de uno (1,00) a las predicciones para el equipo que convierta el primer gol.
                  <br />
                  3. Si un partido se suspende después del inicio del segundo tiempo, y el tiempo restante del partido no se juega dentro del día siguiente al establecido en forma oficial en el Programa (en horario local), la predicción del equipo que anote el primer gol se basará en el resultado al momento de la suspensión.
                  <br />
                  4. Si un partido se suspende, se reanuda y concluye dentro del día siguiente al establecido en forma oficial en el Programa (en horario local), las predicciones del equipo que anote el primer gol se basarán en el resultado registrado al término del partido.
                  <br />
                  5. Si un partido se cancela o se posterga por más de un día en relación con el día establecido en el Programa (en horario local), las apuestas sobre el equipo que convierta el primer gol serán aceptadas, pero la cuota será de uno (1,00).
                  <br />
                  <strong>
                    9. Equipo que anotará o no anotará goles en un partido de
                    fútbol{" "}
                  </strong>
                  <br />
                  El apostador debe predecir si uno o ambos equipos anotarán o no anotarán goles en un partido de fútbol.
                  <br />
                  1. El equipo o los equipos que hayan anotado un gol serán aquellos que el Organizador anuncie oficialmente, basado en los resultados producidos al final del tiempo reglamentario del partido.
                  <br />
                  2. Si un partido es suspendido antes del comienzo del segundo tiempo, después de que un equipo ha anotado goles, y el tiempo restante del partido no se juega dentro del día siguiente al establecido oficialmente en el Programa (en horario local), las predicciones para el equipo que anotó goles serán consideradas ganadoras, mientras que las predicciones dirigidas al equipo que no anotó ningún gol serán consideradas perdedoras. Se les asignará una cuota de uno (1,00) a las predicciones sobre todos los demás resultados.
                  <br />
                  3. Si un partido es suspendido antes del comienzo del segundo tiempo, después de que ambos equipos han anotado goles, y el tiempo restante del partido no se juega dentro del día siguiente al establecido oficialmente en el Programa (en horario local), las apuestas sobre los equipos locales o visitantes que anotaron goles, serán consideradas ganadoras, y cualquier otro resultado será considerado perdedor.
                  <br />
                  4. Si un partido es suspendido antes del comienzo del segundo tiempo, no se ha anotado ningún gol, y el tiempo restante del partido no se juega dentro del día siguiente al que establece el Programa (en horario local), las predicciones sobre cualquier resultado posible tendrán una cuota de uno (1,00).
                  <br />
                  5. Si un partido es suspendido después del comienzo del segundo tiempo, y el tiempo restante del partido no se juega dentro del día siguiente al establecido en el Programa (en horario local), el resultado para este tipo de apuesta se basará en el resultado obtenido al momento de la suspensión.
                  <br />
                  6. Si un partido es suspendido, se reanuda y concluye dentro del día siguiente al establecido en forma oficial en el Programa (en horario local), el resultado de esta apuesta se basará en el resultado registrado al término del partido.
                  <br />
                  7. Si un partido se cancela o posterga por más de un día, en relación con el día establecido en el Programa (en horario local), las predicciones para los equipos que anoten o no anoten goles serán aceptadas, pero la cuota será de uno (1,00).
                  <br />
                  <strong>
                    10. Número total de goles marcados en un partido de fútbol{" "}
                  </strong>
                  <br />
                  El apostador deberá predecir si el número total de goles estará dentro de un cierto rango.
                  <br />
                  1. El número total de goles marcados en un partido de fútbol es la suma de goles convertidos por ambos equipos, según lo anunciado oficialmente por el Organizador y se basa en el resultado producido al final del tiempo reglamentario del partido.
                  <br />
                  2. Si un partido se cancela o se posterga por más de un día en relación con el día establecido en el Programa (en horario local), las predicciones respecto del número total de goles anotados serán aceptadas, pero la cuota será de uno (1,00).
                  <br />
                  3. Si un partido se suspende antes del comienzo del segundo tiempo, y el tiempo restante desde la suspensión hasta el término reglamentario del partido no se juega el día siguiente al establecido en el Programa (en horario local) y, si al momento de la suspensión, el número total de goles se encuentra dentro del rango superior, las predicciones que se encuentren en este rango serán consideradas ganadoras, y todas las demás predicciones que contemplen otros rangos serán consideradas perdedoras.
                  <br />
                  4. Si un partido es suspendido antes del comienzo del segundo tiempo, y el tiempo restante no se juega el día siguiente al establecido en el Programa (en horario local) y, si al momento de la suspensión, el número total de goles marcados se encuentra en un rango inferior al máximo, se asignará una cuota de uno (1,00) a todas las predicciones sobre este tipo de apuestas.
                  <br />
                  5. Si el partido es suspendido durante el segundo tiempo del partido, y el tiempo restante del partido no se juega dentro del día siguiente al establecido por el Programa (en horario local), el número total de goles marcados al momento de la suspensión será considerado el resultado final.
                  <br />
                  6. Si un partido es suspendido, se reanuda y concluye dentro del día siguiente al establecido en el Programa (en horario local), el número de goles válidos será el obtenido al finalizar el partido.
                  <br />
                  <strong>
                    11. Número total de tarjetas (amarillas o rojas) - Número total de penales en un partido de fútbol{" "}
                  </strong>
                  <br />
                  El apostador deberá predecir si el número total de tarjetas (amarillas o rojas) o si el total de penales obtenidos en un determinado partido de fútbol será “Menos” o “Más” en relación con un límite publicado en el Programa.
                  <br />
                  1. El número total de tarjetas (amarillas o rojas) o el número total de penales obtenidos en un determinado partido de fútbol es la suma de todas las tarjetas (amarillas o rojas) o la suma de penales para ambos equipos, tal como lo determina oficialmente el Organizador, basado en los resultados obtenidos al finalizar el tiempo de juego reglamentario del partido de fútbol. El tiempo suplementario y la definición por penales no cuentan para estas apuestas.
                  <br />
                  2. Si un partido es cancelado o postergado por más de 24 horas en relación con lo establecido en el Programa (en horario local), las apuestas serán aceptadas, pero la cuota será de uno (1,00).
                  <br />
                  3. Si un partido es suspendido antes del comienzo del segundo tiempo, y el tiempo reglamentario restante no se juega dentro del día siguiente al establecido en el Programa (en horario local) y, si al momento de la suspensión, el número total de tarjetas (amarillas o rojas) o el número total de penales ejecutados hasta ese momento ha sobrepasado el límite establecido por el Programa, el resultado “más” es considerado ganador, mientras que el resultado “menos” es considerado perdedor. Si un partido se suspende antes del comienzo del segundo tiempo, y el tiempo reglamentario restante no se juega dentro del día siguiente al establecido en el Programa (en horario local) y, si al momento de la suspensión, el número total de tarjetas (amarillas o rojas) o el número total de penales ejecutados hasta ese momento es menor al límite publicado en el Programa, ambos resultados “Más” y “Menos” tendrán una cuota de uno (1,00).
                  <br />
                  4. Si un partido es suspendido durante el segundo tiempo, y el tiempo restante del partido no se juega dentro del día siguiente al establecido en el Programa (en horario local), el número total de tarjetas (amarillas o rojas) o el número total de penales obtenidos al momento de la suspensión será considerado el número total final de tarjetas (amarillas o rojas) o el número total final de penales obtenidos.
                  <br /> 
                  5. Si un partido es suspendido, se reanuda y concluye dentro del día siguiente al establecido oficialmente en el Programa (en horario local), el número total de tarjetas (amarillas o rojas) o el número total de penales ejecutados será el número de tarjetas (amarillas o rojas) o el número de penales obtenidos al finalizar el partido.
                  <br />
                  <strong>
                    12. Tiempo del primer gol de un partido de fútbol
                  </strong>
                  <br /> El apostador deberá predecir en qué rango de tiempo se convertirá el primer gol de un partido de fútbol.
                  <br />
                  1. El primer gol es aquel marcado dentro de los 90 minutos de juego reglamentario, es decir, incluyendo el tiempo adicional otorgado por el árbitro debido a las infracciones y otras eventualidades. El tiempo suplementario y las definiciones por penales no cuentan para estas apuestas.
                  <br />
                  2. Si un partido es suspendido antes del comienzo del segundo tiempo, después de que el primer gol ha sido anotado, y el tiempo restante del partido no se juega dentro del día siguiente al establecido en el Programa (en horario local), las predicciones sobre la hora del primer gol serán consideradas.
                  <br />
                  3. Si un partido es suspendido antes del comienzo del segundo tiempo, no se han convertido goles, y el tiempo restante del partido no se juega dentro del día siguiente al establecido en el Programa (en horario local), las predicciones para la hora del primer gol tendrán una cuota de uno (1,00).
                  <br />
                  4. Si un partido es suspendido después del comienzo del segundo tiempo, y el tiempo restante del partido no se juega dentro del día siguiente al establecido en el Programa (en horario local), la predicción para la hora del primer gol se basará en el resultado obtenido al momento de la suspensión.
                  <br />
                  5. Si un partido es suspendido, se reanuda y concluye dentro del día siguiente al establecido oficialmente en el Programa (en horario local), las predicciones sobre la hora del primer gol se basarán en el resultado obtenido al finalizar el partido.
                  <br />
                  6. Si un partido es cancelado o postergado por más de un día de lo que establece el Programa (en horario local), las predicciones serán aceptadas pero su cuota serán de uno (1,00).
                  <br />
                  7. Las predicciones para la hora del primer gol de un partido de fútbol se pueden combinar en una misma jugada con las predicciones del autor del primer, del último o de cualquier gol en el mismo partido. En caso de que la predicción del autor del primer, del último o de cualquier gol tenga cuota de uno (1,00), la apuesta será examinada como una apuesta sobre la hora del primer gol. En el caso de que la hora del primer gol se combine con una apuesta sobre el anotador del primer, del último o de cualquier gol, y las apuestas para la hora del primer gol tengan una cuota de uno (1,00), todas las apuestas serán reintegradas a los jugadores.
                  <br />
                  <strong>
                    13. Ganador de un torneo de fútbol - Ganador de una semifinal de un torneo de fútbol - Ganadores de fases clasificatorias de grupos de diferentes torneos de fútbol
                  </strong>
                  <br />
                  El apostador deberá predecir qué equipo ganará un torneo de fútbol, cuál ganará las semifinales de un torneo de fútbol o cuál ganará un grupo de las fases clasificatorias de un torneo de fútbol.
                  <br />
                  1. El ganador de un torneo, el ganador de la semifinal o el ganador de alguna ronda del torneo se determinará de acuerdo con las reglas de la competencia, tal como fuera organizada, independientemente de la forma en que sea resuelta, ya sea por puntos, finales (incluyendo tiempo suplementario o definición por penales), clasificación por medio de eliminatorias o sorteo.
                  <br />
                  2. Las apuestas recibidas a favor de un equipo que, por cualquier motivo, fue descalificado o abandonó la competencia, se considerarán perdedoras, excepto cuando el Programa indique lo contrario.
                  <br />
                  3. Se asignará una cuota de uno (1,00) a las jugadas recibidas a favor de un equipo que, por alguna razón, no participó en la competencia.
                  <br />
                  4. En caso de que la competencia sea cancelada por cualquier razón, se asignará a todas las apuestas una cuota de uno (1,00).
                  <br />
                  <strong>
                  13 Bis. Ronda (Fase) del Torneo, en la cual un equipo de fútbol es eliminado por no clasificarse y/o haber sido descalificado.
                  </strong>
                  <br />
                  El apostador podrá predecir en que ronda (Fase) del torneo, un determinado equipo de fútbol será eliminado por no haber podido clasificar y/o habérselo descalificado.
                  <br />
                  1. El Organizador, de conformidad al dictado de las reglas establecidas para el torneo, es el que determinará en que ronda (fase) un equipo se considerará eliminado por no haberse clasificado y/o descalificado, consideración que será independientemente al desarrollo el mismo, en cuanto a clasificación, finales (incluyendo los tiempos suplementarios, o penales), un partido de playoffs para clasificar, o un sorteo.
                  <br />
                  2. Las predicciones recibidas que apuesten a un equipo, donde el mismo por cualquier razón no haya participado en el torneo, tendrán cuotas igual a uno (1,00).
                  <br />
                  3. En el caso que el torneo se cancelará por cualquier motivo, todas las apuestas tendrán cuotas igual a uno (1,00).
                  <br />
                  <strong>
                    14. Máximo goleador del Torneo de fútbol Equipo máximo
                    goleador:
                  </strong>
                  <br />
                  El Apostador podrá predecir cuál de los jugadores de fútbol marcará más goles en todo el Torneo o en una parte de este como así también que jugador de fútbol de entre los jugadores de un equipo marcará más goles en todo el Torneo o en una parte del mismo.
                  <br />
                  1. Para determinar el máximo goleador del Torneo o el máximo goleador del equipo en el Torneo o en una parte de él, se aplicarán las normas establecidas por la entidad organizadora del torneo y los resultados obtenidos dentro del marco de la competencia, excepto que el Programa indique otra posibilidad.
                  <br />
                  2. Cuando se determina el máximo goleador o el máximo goleador del equipo en el Torneo o en una parte de él, se tomarán en cuenta los goles anotados durante el tiempo reglamentario del partido de fútbol y el posible tiempo suplementario, pero no se tomarán en cuenta los goles marcados durante la definición por penales.
                  <br />
                  3. Las apuestas recibidas a favor de un jugador de futbol que no participo en la competencia se considerarán perdedoras.
                  <br />
                  4. Las apuestas recibidas a favor de un jugador de fútbol cuyo equipo fue descalificado del Torneo, tendrán una cuota de uno (1,00).
                  <br />
                  5.  Si la competencia es cancelada, todas las predicciones para máximo goleador tendrán cuota de uno (1,00).
                  <br />
                  6. Si hubiera dos o más jugadores que han anotado el mismo número de goles, y se los considera los máximos goleadores del Torneo o los máximos goleadores del equipo en el Torneo o en una parte de este, la cuota de cada uno de ellos se dividirá por la cantidad de máximos goleadores. Las cuotas que se pagan a los jugadores como consecuencia de la aplicación de esta regla no pueden ser menores a uno (1,00).
                  <br />
                  <strong>
                    15. Grupo del cual proviene el ganador de la competencia
                  </strong>
                  <br />
                  El apostador debe predecir de un número específico de grupos, el grupo del cual provendrá el ganador del torneo. Como grupo se puede considerar cualquier cantidad de eventos (equipos, atletas, ligas, competidores, etc). El organizador del juego o del torneo es el que determinará las formaciones grupos a los fines de las apuestas, acorde con el reglamento correspondiente.
                  <br />
                  1. El grupo del cual proviene el ganador de la competencia será aquel grupo anunciado oficialmente por el organizador.
                  <br />
                  2. Si la competencia es cancelada, cualquier predicción para ese tipo de apuestas, se le asignará una cuota de uno (1,00).
                  <br />
                  <strong>16. Apuesta a Grupo </strong>
                  <br />
                  El apostador debe predecir el resultado de cuál de ellos terminará en mejor posición dentro de un determinado grupo ofrecido (equipo, atleta, competidor, etc), en una determinada competencia o en parte de la misma.
                  <br />
                  1. El resultado final de las posiciones será la que el organizador del juego anuncie, una vez anunciado oficialmente el resultado final o parcial por parte del organizador de la competencia. En caso de que posteriormente ocurra cualquier cambio al resultado este no será considerado. Como ejemplo, que no solo se limita a este ejemplo, sería el caso en que se cambian las posiciones finales de un torneo por descalificar a uno de los competidores (atleta, equipo, competidor, etc).
                  <br />
                  2. En caso de que exista más de un resultado ganador las cuotas serán divididas por la cantidad de resultados ganadores.
                  <br />
                  3. En caso de que se hayan recibido apuestas para un participante que por la razón que sea, no participe en la competencia o en una parte de ella, entonces todas las opciones de resultados del grupo al cual pertenece ese participante recibirán una cuota de uno (1,00).


                  <br />
                  4. Apuestas recibidas para cualquier resultado que por cualquier causa no califique o abandone la competencia, serán consideradas perdedoras.
                  <br />
                  5. En caso de que una competencia sea cancelada, postergada o suspendida indefinidamente y al momento de la suspensión no haya un resultado ganador, todos los pronósticos para ese tipo de apuesta recibirán una cuota de uno (1,00). Salvo que el programa dispusiese algo diferente. Si al momento de la suspensión hubiese un resultado ganador (un resultado que no puede cambiarse aún en el caso que la competencia hubiese continuado o completado), entonces ese resultado será considerado el ganador.
                </p>
                <br />
                <br />
              </DivtextoTyC>
            </DivCardtext>
          </div>
        </DivCard>
      </div>
    )
  );
};

export default CardTerminosCondiciones;

const animacion = keyframes`
 0% {
  position: absolute;
    top: -200px;
    opacity: 0;
 }
 100% {
  position: absolute;
    top: 450px;
    opacuty: 1;
 }
`;
const DivCard = styled.div`
  position: absolute;
  z-index: 1000;
  left: ${(props) => (props.sizeWindow > 500 ? props.sizeWindow / 4 : 0)}px;
  width: ${(props) =>
    props.sizeWindow > 500 ? props.sizeWindow / 2 : props.sizeWindow}px;
  animation: ${animacion} 0.5s;
`;

const DivCardtext = styled.div`
  height: 70vh;
  overflow: auto;
  text-align: justify;
`;

const BotonCerrar = styled.div`
  
`;

const DivtextoTyC = styled.div`
  padding: 0px 20px;
`;
