export const estilosLogo = {
    header: {
        logo: '#fff',
        letter: '#fff',
        mobile: {
            height: 23,
            width: 147
        },
        desktop: {
            height: 23,
            width: 147
        }
    },
    footer: {
        logo: "#ff3900",
        letter: "#fff",
        mobile: {
            height: 23,
            width: 147
        },
        desktop: {
            height: 23,
            width: 147
        }
    },
    loader: {
        logo: "#ff3900",
        letter: "#fff",
        mobile: {
            width: 200
        },
        desktop: {
            width: 400
        }
    }
}
